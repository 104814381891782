// Components
import popup from './components/popup';
import nav from './components/nav';
import initParallax from './components/parallax';
import scrollFade from './components/scroll_fade/scroll_fade';
import customCursor from './components/custom-cursor';
import smoothScroll from './components/smooth-scroll';

// Libraries
import $ from 'jquery';

class App {
	constructor(page, initialized) {
		this.page = page;
		this.initialized = initialized;

		if (this.initialized == false) {
			if (document.readyState === 'loading') {
				document.addEventListener('DOMContentLoaded', this.init());
			} else {
				this.init();
			}
		}
	}

	init() {
		this.initialized = true;
		nav();

		switch (true) {
			case this.page.classList.contains('home'):
				popup();
				initParallax();
				scrollFade();
				smoothScroll();
				break;
		}
	}
}

new App(document.querySelector('body'), false);
